<template>
  <div v-wechat-title="$route.meta.title"></div>
  <div class="background">
    <div class="container">
      <div
        class="info"
        v-infinite-scroll="selectLoveRecord"
        :infinite-scroll-disabled="scrollDisable"
        infinite-scroll-immediate="false"
      >
        <div
          v-for="(item, index) in loveRecordList"
          :key="item.id"
          class="love_record"
        >
          <div class="love_left">
            <div
              class="cricle2"
              v-if="index == 0"
            >
              <div class="infinity_text">&infin;</div>
            </div>

            <div
              class="cricle"
              v-else
            >
              <div class="cricle_text">{{item.create_time_toMath}}月</div>
            </div>
            <div
              class="line2"
              v-if="index == loveRecordList.length - 1"
            ></div>
            <div
              class="line"
              v-else
            ></div>
            <div
              class="cricle2"
              v-if="index == loveRecordList.length - 1"
            >
              X&amp;Y
            </div>
          </div>
          <div class="love_right">
            <div class="right_title">
              <div class="date">{{(item.create_time_toDate).split(' ')[0]}}</div>
              <div class="author">作者：{{item.user_nickname}}</div>
            </div>
            <hr class="love_hr" />
            <div class="right_info">
              <div class="info_bottom">
                <el-form-item label="今日心情:">
                  <i
                    v-if="item.mood == 5"
                    class="iconfont icon-qingtian mood_icon_show"
                  ></i>
                  <i
                    v-if="item.mood == 4"
                    class="iconfont icon-duoyun mood_icon_show"
                  ></i>
                  <i
                    v-if="item.mood == 3"
                    class="iconfont icon-yintian mood_icon_show"
                  ></i>
                  <i
                    v-if="item.mood == 2"
                    class="iconfont icon-xiaoyu mood_icon_show"
                  ></i>
                  <i
                    v-if="item.mood == 1"
                    class="iconfont icon-leizhenyu mood_icon_show"
                  ></i>
                </el-form-item>

                <el-form-item label="对Ta的评分:">
                  <el-rate
                    :model-value="item.rate"
                    :texts="['很差劲', '不及格', '将将及格', '还差一点', '很不错']"
                    show-text
                    :colors="colors"
                    disabled
                  />
                </el-form-item>
              </div>
              <p class="matter">{{item.matter}}</p>
              <div>
                <div v-if="item.picture != '' || item.picture != []">
                  <el-image
                    v-for="(img,index) in JSON.parse(item.picture)"
                    :key="index"
                    v-bind:src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' + img"
                    :preview-src-list="toJson(item.picture)"
                    class="info_image"
                    fit="cover"
                  >
                    <!-- <template #placeholder>
                      <div class="image-slot">
                        <el-icon>
                          <icon-picture />
                        </el-icon>
                      </div>
                    </template>

                    <template #error>
                      <div class="image-slot">
                        <el-icon>
                          <icon-picture />
                        </el-icon>
                      </div>
                    </template> -->
                  </el-image>
                </div>
              </div>
              <div class="address">{{item.create_time_toDate}}&nbsp;&nbsp;&nbsp;来自{{item.address_city}}</div>
            </div>
          </div>
        </div>
        <p
          v-if="loading"
          class="list_bottom"
        >稍等一下，正在加载中...</p>
        <p
          v-if="scrollDisable"
          class="list_bottom"
        >这是我们爱情的起点</p>
        <el-button
          class="phone_addButton"
          type="danger"
          @click="dialogVisible_phone = true"
        >
          写故事
        </el-button>
      </div>
      <div
        class="right"
        style="width:360px;flex-shrink:0"
      >
        <div class="to_top">
          <el-button
            class="addButton"
            type="danger"
            @click="dialogVisible = true"
          >
            发布故事
          </el-button>

          <div
            v-for="item in timeMachineInfo"
            :key="item.id"
            class="time_machine"
          >
            <div class="machine_title">
              <template v-if="item.time_type == 2">
                距离{{item.name}}还有
              </template>
              <template v-else>
                我们已经{{item.name}}
              </template>
            </div>
            <div class="time">
              <div class="days time_item">
                <div class="number">{{item.countdown[0]}}</div>
                <div class="time_name">Days</div>
              </div>
              <div class="colon">
                :
              </div>
              <div class="hours time_item">
                <div class="number">{{item.countdown[1]}}</div>
                <div class="time_name">Hours</div>
              </div>
              <div class="colon">
                :
              </div>
              <div class="minutes time_item">
                <div class="number">{{item.countdown[2]}}</div>
                <div class="time_name">Minutes</div>
              </div>
              <div class="colon">
                :
              </div>
              <div class="second time_item">
                <div class="number">{{item.countdown[3]}}</div>
                <div class="time_name">Second</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增弹窗 -->
  <el-dialog
    v-model="dialogVisible"
    title="发布爱情故事"
    width="656px"
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="今日心情">
          <div style="display:flex">
            <i
              :class="'iconfont icon-qingtian mood_icon ' + (loveRecordFrom.mood == 5 ? 'mood_icon_activate':'')"
              @click="loveRecordFrom.mood = 5"
            ></i>
            <i
              :class="'iconfont icon-duoyun mood_icon ' + (loveRecordFrom.mood == 4 ? 'mood_icon_activate':'')"
              @click="loveRecordFrom.mood = 4"
            ></i>
            <i
              :class="'iconfont icon-yintian mood_icon ' + (loveRecordFrom.mood == 3 ? 'mood_icon_activate':'')"
              @click="loveRecordFrom.mood = 3"
            ></i>
            <i
              :class="'iconfont icon-xiaoyu mood_icon ' + (loveRecordFrom.mood == 2 ? 'mood_icon_activate':'')"
              @click="loveRecordFrom.mood = 2"
            ></i>
            <i
              :class="'iconfont icon-leizhenyu mood_icon ' + (loveRecordFrom.mood == 1 ? 'mood_icon_activate':'')"
              @click="loveRecordFrom.mood = 1"
            ></i>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="对Ta的评分">
          <el-rate
            v-model="loveRecordFrom.rate"
            :texts="['很差劲', '不及格', '将将及格', '还差一点', '很不错']"
            show-text
            :colors="colors"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-input
      v-model="loveRecordFrom.matter"
      :rows="7"
      type="textarea"
      placeholder="今天有什么故事呢？快点写下来吧..."
      class="matter_input"
    />
    <Update
      @updateFrom="updatePictureInfo"
      ref="pictureUpload"
    ></Update>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="dialog_button"
          @click="handleClose()"
        >取消</el-button>
        <el-button
          class="dialog_button"
          type="primary"
          @click="insertLoveRecord()"
        >保存</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="dialogVisible_phone"
    title="发布爱情故事1"
    width="90%"
    :before-close="handleClose_phone"
  >

    <el-form-item label="今日心情">
      <div style="display:flex">
        <i
          :class="'iconfont icon-qingtian mood_icon ' + (loveRecordFrom.mood == 5 ? 'mood_icon_activate':'')"
          @click="loveRecordFrom.mood = 5"
        ></i>
        <i
          :class="'iconfont icon-duoyun mood_icon ' + (loveRecordFrom.mood == 4 ? 'mood_icon_activate':'')"
          @click="loveRecordFrom.mood = 4"
        ></i>
        <i
          :class="'iconfont icon-yintian mood_icon ' + (loveRecordFrom.mood == 3 ? 'mood_icon_activate':'')"
          @click="loveRecordFrom.mood = 3"
        ></i>
        <i
          :class="'iconfont icon-xiaoyu mood_icon ' + (loveRecordFrom.mood == 2 ? 'mood_icon_activate':'')"
          @click="loveRecordFrom.mood = 2"
        ></i>
        <i
          :class="'iconfont icon-leizhenyu mood_icon ' + (loveRecordFrom.mood == 1 ? 'mood_icon_activate':'')"
          @click="loveRecordFrom.mood = 1"
        ></i>
      </div>
    </el-form-item>

    <el-form-item label="对Ta的评分">
      <el-rate
        v-model="loveRecordFrom.rate"
        :texts="['很差劲', '不及格', '将将及格', '还差一点', '很不错']"
        show-text
        :colors="colors"
      />
    </el-form-item>
    <el-input
      v-model="loveRecordFrom.matter"
      :rows="7"
      type="textarea"
      placeholder="今天有什么故事呢？快点写下来吧..."
      class="matter_input"
    />
    <Update
      @updateFrom="updatePictureInfo"
      ref="pictureUpload"
    ></Update>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="dialog_button"
          @click="handleClose_phone()"
        >取消</el-button>
        <el-button
          class="dialog_button"
          type="primary"
          @click="insertLoveRecord()"
        >保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import Update from '../components/update.vue'
import {
  INSERTLOVERECORD,
  SELECTLOVERECORD,
  SELECTTIMEMACHINE
} from '../api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  name: 'Layout',
  components: {
    Update
  },
  setup() {
    onMounted(() => {
      selectLoveRecord()
      selectTimeMachine()
      getTIME()
    })
    const pictureUpload = ref(null)
    const dialogVisible = ref(false)
    const dialogVisible_phone = ref(false)
    const loveRecordFrom = reactive({
      mood: '', // 心情
      rate: '', // 评分
      matter: '', // 内容
      picture: [] // 图片
    })
    const colors = ref(['#99A9BF', '#F7BA2A', '#FF9900'])
    // 更新表单图片信息
    const updatePictureInfo = (pictureinfo) => {
      if (JSON.parse(pictureinfo).length === 0) {
        loveRecordFrom.picture = ''
      } else {
        loveRecordFrom.picture = pictureinfo
      }
    }
    // 新增
    const insertLoveRecord = () => {
      if (loveRecordFrom.mood === '') {
        ElMessage({
          showClose: true,
          message: '忘记选择今日心情了！',
          type: 'warning'
        })
      } else if (loveRecordFrom.rate === 0) {
        ElMessage({
          showClose: true,
          message: '忘记给Ta评分了！',
          type: 'warning'
        })
      } else if (loveRecordFrom.matter === '') {
        ElMessage({
          showClose: true,
          message: '忘记写下今天的故事了！',
          type: 'warning'
        })
      } else {
        ElMessageBox.confirm('确定全部都写好了，准备保存了吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '我再看看',
          type: 'success'
        })
          .then(() => {
            INSERTLOVERECORD(loveRecordFrom).then((response) => {
              if (response.code === -1) {
                ElMessage({
                  showClose: true,
                  message: response.msg,
                  type: 'warning'
                })
              } else if (response.code === 0) {
                // 关闭弹窗
                dialogVisible.value = false
                dialogVisible_phone.value = false
                // 清空表单
                loveRecordFrom.mood = ''
                loveRecordFrom.rate = ''
                loveRecordFrom.matter = ''
                loveRecordFrom.picture = []
                pictureUpload.value.clearPictureInfo()
                loveRecordList.length = 0
                page.value = 1
                selectLoveRecord()
                ElMessage({
                  showClose: true,
                  message: response.msg,
                  type: 'success'
                })
              }
            })
          })
          .catch(() => {
            // catch error
          })
      }
    }
    // 关闭弹窗
    const handleClose = () => {
      // 关闭弹窗
      dialogVisible.value = false
      // 清空表单
      loveRecordFrom.mood = ''
      loveRecordFrom.rate = ''
      loveRecordFrom.matter = ''
      loveRecordFrom.picture = []
      pictureUpload.value.clearPictureInfo()
    }

    const handleClose_phone = () => {
      // 关闭弹窗
      dialogVisible_phone.value = false
      // 清空表单
      loveRecordFrom.mood = ''
      loveRecordFrom.rate = ''
      loveRecordFrom.matter = ''
      loveRecordFrom.picture = []
      pictureUpload.value.clearPictureInfo()
    }
    // 爱情记录信息
    const loveRecordList = reactive([])
    // 页码
    const page = ref(1)
    // 每次加载条数
    const pageSize = ref(10)
    // 是否禁止查询
    const scrollDisable = ref(false)
    // 查询加载状态
    const loading = ref(false)
    // 查询
    const selectLoveRecord = () => {
      loading.value = true
      SELECTLOVERECORD({ page: page.value, pageSize: pageSize.value })
        .then((response) => {
          if (response.code === 0) {
            if (response.data.data.length === 0) {
              scrollDisable.value = true
            } else {
              response.data.data.forEach((element) => {
                loveRecordList.push(element)
              })
              page.value = page.value + 1
            }
            loading.value = false
          }
        })
        .catch(() => {
          console.log('233')
        })
    }
    // 图片列表解析json字符串
    const toJson = (val) => {
      var obj = JSON.parse(val)
      var obj1 = []
      if (obj.length > 0) {
        for (var i = 0; i < obj.length; i++) {
          obj1.push(
            'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
              obj[i]
          )
        }
      }
      return obj1
    }
    // 时光机信息
    const timeMachineInfo = ref([])
    // 查询时光机
    const selectTimeMachine = () => {
      SELECTTIMEMACHINE().then((response) => {
        if (response.code === 0) {
          timeMachineInfo.value = response.data
        }
      })
    }
    const TIME = () => {
      if (timeMachineInfo.value !== []) {
        timeMachineInfo.value.forEach((x) => {
          if (x.time_type === 1) {
            x.countdown = getDuration(
              parseInt(new Date().getTime() / 1000) - Number(x.timestamp)
            )
          } else {
            x.countdown = getDuration(
              Number(x.timestamp) - parseInt(new Date().getTime() / 1000)
            )
          }
        })
      }
    }

    function getDuration(second) {
      var days = Math.floor(second / 86400)
      var hours = Math.floor((second % 86400) / 3600)
      var minutes = Math.floor(((second % 86400) % 3600) / 60)
      var seconds = Math.floor(((second % 86400) % 3600) % 60)
      // let duration
      // const duration =
      // days + '天' + hours + '小时' + minutes + '分' + seconds + '秒'
      return [days, hours, minutes, seconds]
    }

    function getTIME() {
      setInterval(TIME, 500)
    }

    return {
      pictureUpload,
      dialogVisible,
      dialogVisible_phone,
      loveRecordFrom,
      colors,
      updatePictureInfo,
      insertLoveRecord,
      handleClose,
      handleClose_phone,
      loveRecordList,
      toJson,
      selectLoveRecord,
      scrollDisable,
      loading,
      timeMachineInfo
    }
  }
}
</script>

<style>
.el-rate__item {
  display: flex;
}
</style>
<style src="../css/loveRecord.css" scoped>
</style>
<style src="../css/icon/iconfont.css">
</style>
